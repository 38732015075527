import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import classnames from "classnames/bind"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
// Component
import { Trophy, Briefcase } from "phosphor-react"
// Style
import experienceStyle from "../../style/components/about/experience-block.module.scss"

const FullExperienceBlock = props => {
  const dividerRef = useRef(null)
  const blockRef = useRef(null)

  useEffect(() => {
    const divider = dividerRef.current
    const block = blockRef.current

    gsap.timeline({
      scrollTrigger: {
        id: "dividerAnimation",
        trigger: block,
        start: "top bottom-=80",
        end: "bottom top",
        markers: false,
        toggleClass: {
          targets: divider,
          className: experienceStyle.is_inview,
        },
      },
    })

    return () => {
      if (ScrollTrigger.getById("dividerAnimation")) {
        ScrollTrigger.getById("dividerAnimation").kill()
      }
    }
  }, [])

  return pug`
    div.col-12.flex.flex-row(className=experienceStyle.wrapper ref=blockRef)
      div.flex.items-center.justify-center.overflow-hidden(className=classnames(experienceStyle.image_wrapper,props.bgColor))
        if (props.icon == "icon")
          Img(fluid=props.fluid style={ height:'100%', width:'100%' })
        if (props.icon == "trophy")
          Trophy
        if (props.icon == "briefcase")
          Briefcase
      div.flex.flex-col.justify-between(className=classnames(experienceStyle.text_wrapper,"md:flex-row","md:items-center"))
        div
          h5.text-theme-100.capitalize(className=experienceStyle.text_name)=props.name
        div.flex-grow-0.flex-shrink-0(className="md:text-right")
          span.block.text-theme-100.font-semibold.uppercase.mb-1(className=experienceStyle.text_title)=props.title
          if (props.date)
            span.block.text-theme-300.mb-2(className=experienceStyle.text_date)=props.date
    div(className=experienceStyle.text_divider ref=dividerRef)
  `
}

// const ColumnExperienceBlock = props => {
//   return pug`
//     div.col-12.flex.flex-row.mb-10.px-0(className=props.className)
//       div.flex.items-center.justify-center(className=classnames(experienceStyle.image_wrapper,props.bgColor))
//         if (props.icon == "trophy")
//           Trophy(size=48)
//         if (props.icon == "briefcase")
//           Briefcase(size=48)
//       div(className=experienceStyle.text_wrapper)
//         h5.text-theme-100.capitalize(className=experienceStyle.text_name)=props.name
//         span.block.text-theme-200.font-semibold.uppercase.mb-1(className=experienceStyle.text_title)=props.title
//         span.block.text-theme-300.mb-2(className=experienceStyle.text_date)=props.date
//         p.text-theme-200(className=experienceStyle.text_content)=props.content
//   `
// }

// const ColumnExperienceBlockNoIcon = props => {
//   return pug`
//     div.col-12.flex.flex-row.mb-10.px-0(className=props.className)
//       div.ml-0(className=experienceStyle.text_wrapper)
//         h5.text-gray-100.capitalize(className=experienceStyle.text_name)=props.name
//         span.block.text-gray-200.font-semibold.uppercase.mb-1(className=experienceStyle.text_title)=props.title
//         span.block.text-gray-300.mb-2(className=experienceStyle.text_date)=props.date
//         p.text-gray-200(className=experienceStyle.text_content)=props.content
//   `
// }

export {
  FullExperienceBlock,
  // ColumnExperienceBlock,
  // ColumnExperienceBlockNoIcon,
}
