import React, { useEffect, useRef } from "react"
import Link from "gatsby-plugin-transition-link"
import classnames from "classnames/bind"
import {
  InstagramLogo,
  FileArrowDown,
  Envelope,
  DribbbleLogo,
  LinkedinLogo,
} from "phosphor-react"
import gsap from "gsap"

// Component
import { WideContainer } from "./container"

// Style
import socialStyle from "../style/layouts/social.module.scss"

// TODO: Social hover effect

export default function Social(props) {
  // fadeInRef Array
  const fadeInRef = useRef([])
  const addToRefs = el => {
    if (el && !fadeInRef.current.includes(el)) {
      fadeInRef.current.push(el)
    }
  }

  useEffect(() => {
    gsap
      .timeline()
      .set(fadeInRef.current, {
        opacity: 0,
      })
      .to(fadeInRef.current, {
        opacity: 1,
        stagger: {
          amount: 0.3,
        },
        delay: 1,
      })
  }, [])

  return pug`
    div.social.fixed.w-full.bottom-0.d-only-pc.mb-6.z-ground
      WideContainer.relative.h-full.flex.items-start.justify-start.flex-col
        a(className=socialStyle.itemWrapper href="https://drive.google.com/file/d/1vJCNcrfMmeauokCqmG0U9RJ_gO8bESmO/view?usp=sharing" alt="resume" target="_blank" rel="noopener" ref=addToRefs)
          div(className=classnames(socialStyle.item,props.darkmode?socialStyle.darkmode:socialStyle.lightmode))
            FileArrowDown(size=24)
        a(className=socialStyle.itemWrapper href="https://www.linkedin.com/in/zoe-chou-c3/u" alt="linkedin" target="_blank" rel="noopener" ref=addToRefs)
          div(className=classnames(socialStyle.item,props.darkmode?socialStyle.darkmode:socialStyle.lightmode))
            LinkedinLogo(size=24)
        a(className=socialStyle.itemWrapper href="https://dribbble.com/ChiaChingChou" alt="dribble" target="_blank" rel="noopener" ref=addToRefs)
          div(className=classnames(socialStyle.item,props.darkmode?socialStyle.darkmode:socialStyle.lightmode))
            DribbbleLogo(size=24)
        // Link(className=socialStyle.itemWrapper to="/templates/headline/" )
        //   div(className=classnames(socialStyle.item,props.darkmode?socialStyle.darkmode:socialStyle.lightmode))
        //     InstagramLogo(size=24)
        a(className=socialStyle.itemWrapper href="mailto:chiachingc3@gmail.com" alt="contact" ref=addToRefs)
          div(className=classnames(socialStyle.item,props.darkmode?socialStyle.darkmode:socialStyle.lightmode))
            Envelope(size=24)
  `
}
