import React, { useEffect, useRef } from "react"
import classnames from "classnames/bind"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

// Component
import { ArrowDown } from "phosphor-react"
import { WideContainer } from "./container"

// Style
import scrolldownStyle from "../style/layouts/scrolldown.module.scss"

export default function ScrollDown(props) {
  const fadeOutRef = useRef(null)

  // - Animate
  useEffect(() => {
    gsap.to(fadeOutRef.current, {
      ease: "none",
      opacity: 0,
      x: "40%",
      scrollTrigger: {
        trigger: fadeOutRef.current,
        start: "top+=50 bottom-=50",
        end: "bottom center+=100 ",
        scrub: true,
        markers: false,
      },
    })
  }, [])

  return pug`
    div.scrolldown.fixed.right-0.z-basement.d-only-pc(className=scrolldownStyle.scrolldown ref=fadeOutRef)
      div.flex.flex-col.items-center.justify-center
        span.txt-wide.whitespace-no-wrap.text-rotate.mb-4 SCROLL DOWN
        ArrowDown(size=24)
  `
}
