import React, { useEffect, useState, useRef, useCallback } from "react"
import { graphql } from "gatsby"
import classnames from "classnames/bind"
import { motion, useCycle } from "framer-motion"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { useLocation } from "@reach/router"
// Component
import LayoutWrapper from "../../layouts/wrapper"
import ScrollWrapper from "../../layouts/scroll-wrapper"
import Metadata from "../../layouts/metadata"
import Header from "../../layouts/header"
import { MainContainer } from "../../layouts/container"
import { FullSizeBlock, ColumSizeBlock } from "../../components/block"
import Footer from "../../layouts/footer"
import ExprSection from "../../components/list"
import Divider from "../../components/divider"
import Marquee from "../../components/marquee"
import Cursor from "../../components/cursor"
import Social from "../../layouts/social"
import ScrollDown from "../../layouts/scrolldown"
// Style
import layoutStyle from "../../style/layouts/wrapper.module.scss"
// section
import HomepageProjects from "../../section/homepage/homepage-projects"
import HomepageExperience from "../../section/homepage/homepage-experience"

const Headline = props => {
  // ? 使用framer motion的useViewportScroll()製作scroll animation，
  // ? 但因為framer motion在自訂value，改變後無法更新animation，位置無法傳value進去後改變，所以不採用
  // ? 以下為備份
  /*
  const target = useRef(null)

  // - Stores the start and end scrolling position for our container
  const [scrollPercentageStart, setScrollPercentageStart] = useState(null)
  const [scrollPercentageEnd, setScrollPercentageEnd] = useState(null)

  // - Background Change
  const { scrollYProgress } = useViewportScroll()
  const backgroundColor = useTransform(
    scrollYProgress,
    [scrollPercentageStart, scrollPercentageEnd],
    ["#ffffff", "#F5F0FA"]
  )

  useLayoutEffect(() => {
    const targetElement = target.current
    if (targetElement !== null) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const elementPosition = targetElement.offsetTop + scrollTop
      const elementHeight = targetElement.clientHeight
      const offsetStart = elementPosition
      const offsetEnd = elementPosition + elementHeight
      const elementScrollStart = offsetStart / document.body.clientHeight
      const elementScrollEnd = offsetEnd / document.body.clientHeight

      // update Values
      setScrollPercentageStart(elementScrollStart.toFixed(1))
      setScrollPercentageEnd(elementScrollEnd.toFixed(1))
    }
  }, [target])
  */
  // - Data
  const { pathname } = useLocation()

  // fadeInRef Array
  const fadeInRef = useRef([])
  fadeInRef.current = []

  // - Animate
  // useEffect(() => {
  //   if (typeof window === "undefined" || !window.document) {
  //     console.log(`windows error`)
  //   }

  // gsap
  //   .timeline({
  //     ease: "none",
  //     scrollTrigger: {
  //       id: "layoutWrapperBgChange",
  //       trigger: "#HomepageExpr",
  //       start: "top center",
  //       end: "bottom top",
  //       markers: false,
  //       toggleClass: {
  //         targets: "#layoutWrapper",
  //         className: "bg-primary-light2",
  //       },
  //     },
  //   })
  //   .from("#layoutWrapper", {})
  //   .to("#layoutWrapper", {})

  // gsap.timeline({
  //   ease: "none",
  //   scrollTrigger: {
  //     id: "marqueeTextChange",
  //     trigger: "#HomepageExpr",
  //     start: "top center",
  //     end: "bottom top",
  //     markers: false,
  //     toggleClass: {
  //       targets: "#marqueeText",
  //       className: "txt-hasShadow-primary-light2",
  //     },
  //   },
  // })

  // fadeInRef.current.forEach((el, index) => {
  //   gsap
  //     .timeline({
  //       ease: "none",
  //       duration: 0.5,
  //       scrollTrigger: {
  //         id: `fadeIn-${index + 1}`,
  //         trigger: el,
  //         start: "top-=50 bottom",
  //         toggleActions: "play none none reverse",
  //         markers: true,
  //       },
  //     })
  //     .from(el, {
  //       autoAlpha: 0,
  //       y: 40,
  //     })
  //     .to(el, {
  //       autoAlpha: 1,
  //       y: 0,
  //     })
  // })

  //   return () => {
  //     // ? Cant kill all the Scrolltrigger or gsap at once due to page transition.
  //     // ? it will also kill all new page animation and cause animation failure.
  //     // ? Can only kill by ID
  //     ScrollTrigger.getById("layoutWrapperBgChange").kill()
  //     ScrollTrigger.getById("marqueeTextChange").kill()
  //     // fadeInRef.current.forEach(index => {
  //     //   ScrollTrigger.getById(`fadeIn-${index + 1}`).kill()
  //     // })

  //     // ScrollTrigger.getAll().forEach(ST => ST.kill())
  //     // gsap.globalTimeline.clear()

  //     // Smooth Scroll
  //     // if (locoScroll) locoScroll.destroy()
  //   }
  // }, [])

  // Add children to Ref Array
  const addToRefs = el => {
    if (el && !fadeInRef.current.includes(el)) {
      fadeInRef.current.push(el)
    }
  }

  return pug`
    Metadata(title="Home")
    LayoutWrapper.bg-white.overflow-hidden
      Header(link3=true)
      Social
      ScrollDown
      // ScrollWrapper
      MainContainer
        HomepageProjects
        HomepageExperience
      Footer
  `
}

export default Headline
